import * as yup from "yup";
import {
  ContractRoleTranslationDTO,
  schemaContractRoleTranslationDTO,
} from "./translation/ContractRoleTranslationDTO";

export interface ContractRoleDTO {
  id: number;
  name: string;
  deactivatedDate: Date | null;
  version: string;
  effectiveRole: boolean;
  contractRoleTranslations: ContractRoleTranslationDTO[];
}

export const schemaContractRoleDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  deactivatedDate: yup.date().nullable().defined(),
  version: yup.string().defined(),
  effectiveRole: yup.bool().required().defined(),
  contractRoleTranslations: yup
    .array(schemaContractRoleTranslationDTO)
    .defined(),
});
