import { useCallback, useContext, useMemo, useRef, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ContextMenu } from "../../../../../../../../shared/common/react/components/menu/ContextMenu";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedTableExternalFilterRows,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { ActivityDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { PermissionLevel } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { StyledSummaryTab } from "../../SummaryTab/SummaryTabWithinContext/index.styles";
import { ActivitiesSummary, ActivitiesSummaryRef } from "../ActivitiesSummary";
import { useCreationForm } from "../hooks/useCreationForm";
import { useCreationManyForm } from "../hooks/useCreationManyForm";
import { useDeprovisionCreationForm } from "../hooks/useDeprovisionCreationForm";
import { useFilterForm } from "../hooks/useFilterForm";
import { useTableData } from "../hooks/useTableData";
import { ActivitiesSuperUserContext } from "../shared/react/contexts/ActivitiesSuperUserContext";
import { StyledFooterSumActivities, StyledLeftActions } from "./index.styles";
import { useDeletionManyForm } from "../hooks/useDeletionManyForm";
import { TypeOfActivities } from "../hooks/useFilterForm/shared/types/filter.types";

export const ActivitiesTabWithinContext = () => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const activitiesSummaryRef = useRef<ActivitiesSummaryRef>(null);
  const [showSelected, setShowSelected] = useState<boolean>(false);

  const { accessMode } = useProjectContext();
  const { canEditAllActivitiesButton, selectedActivities } = useContext(
    ActivitiesSuperUserContext
  );

  const reloadTablePage = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);
  const reloadActivitySummary = useCallback(() => {
    activitiesSummaryRef.current?.reloadActivitySummary();
  }, []);

  const { filterButton, filterModal, filters } = useFilterForm();
  const {
    headCells,
    singlePageLoader,
    deletionModal,
    editingModal,
    deprovisionEditingModal,
    closingModal,
    reopeningModal,
  } = useTableData({
    reloadTablePage,
    reloadActivitySummary,
    showSelected,
    filters,
  });
  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
    reloadActivitySummary,
  });
  const { DeleteManyButtonContainer, deletionManyModal } = useDeletionManyForm({
    reloadTablePage,
    reloadActivitySummary,
    setShowSelected,
  });
  const { creationManyButton, creationManyModal, viewCreateManyModal } =
    useCreationManyForm();
  const { deprovisionCreationButton, deprovisionCreationModal } =
    useDeprovisionCreationForm({
      reloadTablePage,
      reloadActivitySummary,
    });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filters, showSelected],
    rendersBeforeEffect: 1,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {accessMode === "writing" && canEditAllActivitiesButton}
        {filterButton}
        <ContextMenu
          menuOptions={[
            {
              Icon: CheckCircleOutlineIcon,
              content: t("activities.actions.general.selectActivity"),
              onClick: () => setShowSelected(true),
            },
          ]}
        />
      </>
    );
  }, [filterButton, canEditAllActivitiesButton, accessMode, showSelected]);

  const footerContent = useMemo(() => {
    if (showSelected) {
      return (
        <StyledFooterSumActivities>
          <StyledLeftActions>
            <Button
              variant="text"
              size="small"
              onClick={() => setShowSelected(false)}
            >
              {t("general.actions.general.cancel")}
            </Button>
            {selectedActivities.length === 0 ? (
              <Button variant="contained" color="error" size="small" disabled>
                {t("activities.actions.general.deleteSelection")}
              </Button>
            ) : (
              <DeleteManyButtonContainer activities={selectedActivities} />
            )}
          </StyledLeftActions>
        </StyledFooterSumActivities>
      );
    }
  }, [showSelected, selectedActivities.length]);

  const filterRows = useCallback<
    ExternalEnhancedTableExternalFilterRows<ActivityDTO>
  >(
    ({ rowInfo }) => {
      if (!rowInfo) return false;

      if (
        filters.scheduledDateEarliest &&
        rowInfo.scheduledDate < filters.scheduledDateEarliest
      )
        return false;
      if (
        filters.scheduledDateLatest &&
        rowInfo.scheduledDate > filters.scheduledDateLatest
      )
        return false;
      if (filters.typeOfActivity?.label) {
        if (
          filters.typeOfActivity.id === TypeOfActivities.Activities &&
          rowInfo.amount < 0
        )
          return false;
        if (
          filters.typeOfActivity.id === TypeOfActivities.Desprovisions &&
          rowInfo.amount > 0
        )
          return false;
      }

      return true;
    },
    [filters]
  );

  return (
    <StyledSummaryTab>
      <ActivitiesSummary ref={activitiesSummaryRef} />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: accessMode === "writing" && (
            <Protected
              restrictions={{
                type: PermissionType.Activity,
                level: PermissionLevel.Create,
              }}
            >
              {creationButton}
              {deprovisionCreationButton}
              {creationManyButton}
            </Protected>
          ),
          rightActionButtons,
        }}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: `calc(100vh - 290px)`,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
        orderDefaults={{
          defaultOrderBy: showSelected ? 3 : 2,
        }}
        footerSettings={{
          content: footerContent,
        }}
        filterSettings={{
          filterRows: filterRows as ExternalEnhancedTableExternalFilterRows,
        }}
      />
      {filterModal}
      {creationModal}
      {deprovisionCreationModal}
      {deletionModal}
      {editingModal}
      {deprovisionEditingModal}
      {closingModal}
      {reopeningModal}
      {creationManyModal}
      {viewCreateManyModal}
      {deletionManyModal}
    </StyledSummaryTab>
  );
};
