import * as yup from "yup";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback, useContext, useMemo } from "react";
import { StopCircle as StopCircleIcon } from "@mui/icons-material";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { closeActivity } from "../../../../../../../../../services/activities/activities.service";
import { convertTo } from "../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { ClosingActivityFormValues } from "../../shared/types/data/form/values.types";
import { ActivityDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { ProjectDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ClosingActivityForm } from "./ClosingActivityForm";
import { ActivityCloseDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityCloseDTO";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { ActivitiesSuperUserContext } from "../../shared/react/contexts/ActivitiesSuperUserContext";
import { DateOnly } from "../../../../../../../../../shared/common/classes/data/date/DateOnly";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
}

interface InternalFormValues {
  project: ProjectDTO | null;
  activity: ActivityDTO | null;
}

export const useCloseActivityForm = ({
  reloadTablePage,
  reloadActivitySummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);

  const formikConfig = useMemo(() => {
    const initialValues: ClosingActivityFormValues = {
      completedDate: null,
    };

    const validationSchema = yup.object({
      completedDate: yup
        .date()
        .nullable()
        .withMutation((schema) =>
          canEditAllActivities
            ? schema
                .min(
                  project.scheduledStartDate ?? new Date(),
                  t("general.errors.data.fields.dates.dateOlderAcceptableLimit")
                )
                .required(t("general.errors.data.fields.general.required"))
            : schema
        ),
    });

    return { initialValues, validationSchema };
  }, [canEditAllActivities, project.scheduledStartDate]);

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      ClosingActivityFormValues,
      InternalFormValues,
      ActivityDTO
    >
  >(
    ({
      contentButtonContentProps: externalActivity,
      setFormValues,
      setInternalFormData,
    }) => {
      setInternalFormData({ project, activity: externalActivity });
      setFormValues({
        completedDate: externalActivity.completedDate?.toDate() ?? null,
      });
    },
    [project]
  );

  const { ContentButton: ClosingButtonContainer, contentModal: closingModal } =
    useFormikModalButton<
      ClosingActivityFormValues,
      InternalFormValues,
      ActivityDTO
    >({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("activities.modal.data.close.title"),
        internalLoadingPosition: "title",
        modalMode: "dialog",
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();

          return (
            <SimpleIconButton
              Icon={StopCircleIcon}
              onClick={onClick}
              color="primary"
              tooltipText={t("activities.actions.general.closeActivity")}
            />
          );
        },
        onClickContentButtonComponent,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <ClosingActivityForm
            project={internalFormData?.project ?? null}
            activity={internalFormData?.activity ?? null}
            {...rest}
          />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("activities.actions.general.closeActivity")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!internalFormData?.activity)
            throw new Error("'activity' cannot be null on submit.");

          try {
            await closeActivity(
              convertTo<ActivityCloseDTO>({
                id: internalFormData.activity.id,
                completedDate: formValues.completedDate
                  ? DateOnly.createFromDate(formValues.completedDate)
                  : undefined,
                version: internalFormData.activity.version,
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            reloadActivitySummary();
            closeModal();

            if (project.isValid) reloadProject();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "activities.errors.data.close.failedToCloseActivity",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { ClosingButtonContainer, closingModal };
};
