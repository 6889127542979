import { TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getJobRoles } from "../../../../../../../../../services/jobRole/jobRole.service";
import { AsyncFastAutocompleteFormikProps } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SearchAutocompleteFormik } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { JobRoleListByFiltersDTO } from "../../../../../../../DTOs/jobRole/filters/JobRoleListByFiltersDTO";
import { JobRoleDTO } from "../../../../../../../DTOs/jobRole/JobRoleDTO";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    JobRoleDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  filters?: Omit<JobRoleListByFiltersDTO, "name">;
  filtersMemo?: Omit<JobRoleListByFiltersDTO, "name">;
  shouldReplaceValueOnMount?: boolean;
  onlyActivated?: boolean;
}

export const JobRolesSearchAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
  shouldReplaceValueOnMount = true,
  onlyActivated = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getJobRolesFinal = useCallback(
    async (name: string) => {
      const paginatedJobRoles = await getJobRoles({
        filters: { name, onlyActivated, ...finalFilters },
        pagination: { pageSize: 10 },
      });
      return paginatedJobRoles.tableData;
    },
    [finalFilters]
  );

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("jobRoles.errors.data.fetch.failedToFetchJobRoles")}
      fetchOptionsMemo={getJobRolesFinal}
      textfieldProps={{
        label: t("jobRoles.keywords.general.jobRolePlc"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${
            x.jobRoleTranslations.find((y) => y.idLanguage === i18n.language)
              ?.name ?? x.name
          } ${
            x.deactivatedDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }` ??
          `${x.name} ${
            x.deactivatedDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
