import { uploadInvoiceFile } from "../../../../../../../services/InvoiceFiles/invoiceFiles.service";
import {
  UpdateToAnalysis,
  UpdateToCancellation,
  UpdateToIssued,
  UpdateToLoss,
  UpdateToPaidOut,
  UpdateToPdd,
  UpdateToProvisionCancellation,
  UpdateToReturned,
} from "../../../../../../../services/invoices/invoices.service";
import { DateOnly } from "../../../../../../../shared/common/classes/data/date/DateOnly";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { InvoiceDTO } from "../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { InvoiceFilesTypeEnum } from "../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import { InvoiceTypeEnum } from "../../../../../../../shared/specific/enums/invoices/InvoiceTypeEnum";
import { InvoicesStatusEnum } from "../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonInvoicesFormValues } from "../types/form/general.types";

interface OwnProps {
  invoiceData: InvoiceDTO | null;
  values: CommonInvoicesFormValues;
  canEditAllInvoices: boolean;
}

export const HandleUpdateInvoice = async ({
  invoiceData,
  values,
  canEditAllInvoices,
}: OwnProps) => {
  switch (values.status?.id) {
    case InvoicesStatusEnum.Analysis:
      await UpdateToAnalysis({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        idCostCenterPepToInvoice: invoiceData?.costCenterPep?.id ?? 0,
        idCustomer: invoiceData?.customer?.id ?? 0,
        status: InvoicesStatusEnum.Analysis,
        invoiceType: invoiceData?.invoiceType ?? InvoiceTypeEnum.InvoiceNote,
        invoiceCompetence: invoiceData?.invoiceCompetence.map(
          (x) => x.yearMonth
        ),
        limitDate: invoiceData?.limitDate ?? DateOnly.createFromNow(),
        invoiceFinancialResponsible:
          invoiceData?.invoiceFinancialResponsible.map((x) => x.email),
        serviceDescription: invoiceData?.serviceDescription || "",
        launchInstructions: invoiceData?.launchInstructions || "",
        invoiceNote: invoiceData?.invoiceNote || undefined,
        invoiceCopyToUser: invoiceData?.invoiceCopyToUser.map((x) => x.id),
        requestDate: DateOnly.createFromNow(),
        invoiceFilesToDelete: [],
        responsibleLogin: values.responsible?.login ?? "",
        version: invoiceData?.version,
      });

      break;
    case InvoicesStatusEnum.Returned:
      await UpdateToReturned({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.Returned,
        reasonForReturn: values.reasonForReturn ?? "",
        responsibleLogin: values.responsible?.login ?? "",
        version: invoiceData?.version,
      });

      break;
    case InvoicesStatusEnum.Issued:
      await UpdateToIssued({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.Issued,
        dueDate: values?.dueDate
          ? DateOnly.createFromDate(values?.dueDate)
          : DateOnly.createFromNow(),
        invoiceFilesToDelete: values.invoiceFilesToDelete,
        invoiceNumber: values?.invoiceNumber ?? "",
        issueDate: values?.issueDate
          ? DateOnly.createFromDate(values?.issueDate)
          : DateOnly.createFromNow(),
        responsibleLogin: values.responsible?.login ?? "",
        hasSuperUser: canEditAllInvoices,
        version: invoiceData?.version,
      });

      await uploadInvoiceFile(
        values.invoiceFilesToUpload,
        invoiceData?.invoiceGroup
          ? invoiceData.invoiceGroup.map((x) => x.id.toString())
          : [],
        InvoiceFilesTypeEnum.Issued
      );

      break;
    case InvoicesStatusEnum.PaidOut:
      await UpdateToPaidOut({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.PaidOut,
        paymentDate: values?.paymentDate
          ? DateOnly.createFromDate(values?.paymentDate)
          : DateOnly.createFromNow(),
        responsibleLogin: values.responsible?.login ?? "",
        version: invoiceData?.version,
      });

      break;
    case InvoicesStatusEnum.Pdd:
      await UpdateToPdd({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.Pdd,
        pddDate: values?.pddDate
          ? DateOnly.createFromDate(values?.pddDate)
          : DateOnly.createFromNow(),
        responsibleLogin: values.responsible?.login ?? "",
        version: invoiceData?.version,
      });

      break;
    case InvoicesStatusEnum.Loss:
      await UpdateToLoss({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.Loss,
        lossDate: values?.lossDate
          ? DateOnly.createFromDate(values?.lossDate)
          : DateOnly.createFromNow(),
        responsibleLogin: values.responsible?.login ?? "",
        version: invoiceData?.version,
      });

      break;
    case InvoicesStatusEnum.ProvisionForCancellation:
      await UpdateToProvisionCancellation({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.ProvisionForCancellation,
        plannedCancellationDate: values?.plannedCancellationDate
          ? DateOnly.createFromDate(values?.plannedCancellationDate)
          : DateOnly.createFromNow(),
        reasonForCancellation: values?.reasonForCancellation ?? "",
        responsibleLogin: values.responsible?.login ?? "",
        percentageToCancel: parseLocaleNumber(values.percentageToCancel),
        version: invoiceData?.version,
      });

      break;
    case InvoicesStatusEnum.Canceled:
      await UpdateToCancellation({
        invoiceIds: invoiceData?.invoiceGroup
          ? invoiceData?.invoiceGroup.map((x) => x.id)
          : [],
        status: InvoicesStatusEnum.Canceled,
        cancellationDate: values?.cancellationDate
          ? DateOnly.createFromDate(values?.cancellationDate)
          : DateOnly.createFromNow(),
        reasonForCancellation: values.reasonForCancellation,
        responsibleLogin: values.responsible?.login ?? "",
        version: invoiceData?.version,
      });

      break;
    default:
      notifyIf4xxApiErrorDTO({
        error: "",
        defaultMessage: "general.errors.data.general.dependencyError",
      });

      break;
  }
};
