import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getJobRates } from "../../../../../../services/jobRate/jobRate.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/data.types";
import { ExternalEnhancedTableExternalPageChanger } from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/propsRef.types";
import { JobRateFilterDTO } from "../../../../../../shared/specific/DTOs/jobRate/filters/JobRateFilterDTO";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useViewingForm } from "./useViewingForm";

interface OwnProps {
  filters?: JobRateFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const { ViewingButtonContainer, viewingModal } = useViewingForm();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("jobRoles.keywords.general.jobRole"),
      t("jobRates.keywords.general.startYearMonth"),
      t("subsidiaries.keywords.general.subsidiary"),
      t("general.keywords.fields.currency"),
      t("jobRates.keywords.general.monthlyRate"),
      t("jobRates.keywords.general.monthlyTax"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onJobRatePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        if (!filters || Object.values(filters).every((x) => !x)) {
          setInfo(t("allocationsMap.info.data.general.fillTheFilter"));
          return null;
        }

        const headKeys = [
          "jobRole.name",
          "startYearMonth",
          "subsidiary.name",
          "subsidiary.currency",
          "monthlyRate",
          "monthlyTax",
        ];

        try {
          const {
            tableData: jobRatesData,
            page,
            totalCount,
          } = await getJobRates({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = jobRatesData.map(
            (jobRateData): ExternalEnhancedRow => ({
              id: jobRateData.id,
              cells: [
                `${
                  jobRateData.jobRole.jobRoleTranslations.find(
                    (x) => x.idLanguage === i18n.language
                  )?.name
                } ${
                  jobRateData.jobRole.deactivatedDate
                    ? `(${t(
                        "jobRates.keywords.general.deactivated"
                      ).toUpperCase()})`
                    : ""
                }` ??
                  `${jobRateData.jobRole.name} ${
                    jobRateData.jobRole.deactivatedDate
                      ? `(${t(
                          "jobRates.keywords.general.deactivated"
                        ).toUpperCase()})`
                      : ""
                  }`,
                jobRateData.startYearMonth.toString(),
                jobRateData.subsidiary.name,
                jobRateData.subsidiary.currency,
                jobRateData.monthlyRate.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                }),
                jobRateData.monthlyTax?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                }) ?? "",
                {
                  CellRenderer: () => (
                    <>
                      <ViewingButtonContainer {...jobRateData} />
                      <Protected
                        restrictions={{
                          type: PermissionType.JobRate,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...jobRateData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.JobRate,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...jobRateData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onJobRatePageChange,
    deleteDialog: deletionModal,
    editingModal,
    viewingModal,
  };
};
