import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getContractRoles } from "../../../../../../services/contractRole/ContractRole.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/data.types";
import { ExternalEnhancedTableExternalPageChanger } from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/propsRef.types";
import { ContractRoleFiltersDTO } from "../../../../../../shared/specific/DTOs/ContractRole/filters/ContractRoleFiltersDTO";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";

interface OwProps {
  filters?: ContractRoleFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      t("contractRole.keywords.fields.effectiveRole"),
      t("contractRole.keywords.fields.deactivatedDate"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onContractRolePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["name", "effectiveRole", "deactivatedDate"];
        try {
          const {
            tableData: contractRolesData,
            page,
            totalCount,
          } = await getContractRoles({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });
          const rows = contractRolesData.map(
            (contractRoleData): ExternalEnhancedRow => ({
              id: contractRoleData.id,
              cells: [
                contractRoleData.contractRoleTranslations.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? contractRoleData.name,
                contractRoleData.effectiveRole
                  ? t("general.keywords.general.yes")
                  : t("general.keywords.general.no"),
                {
                  value: contractRoleData.deactivatedDate,
                  displayValue:
                    contractRoleData.deactivatedDate?.toLocaleDateString(
                      i18n.language
                    ) ?? "",
                },
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.ContractRole,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...contractRoleData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.ContractRole,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...contractRoleData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );
          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onContractRolePageChange,
    deleteDialog: deletionModal,
    editingModal,
  };
};
