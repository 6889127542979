import { saveAs } from "file-saver";
import { riseApi } from "./connection";
import {
  UserCollaboratorProfileDTO,
  schemaUserCollaboratorProfileDTO,
} from "../../../shared/specific/DTOs/userCollaboratorProfile/UserCollaboratorProfileDTO";
import { UserCertificationCreateDTO } from "../../../shared/specific/DTOs/userCertification/UserCertificationCreateDTO";
import {
  UserLanguageDTO,
  schemaUserLanguageDTO,
} from "../../../shared/specific/DTOs/userLanguage/UserLanguageDTO";
import {
  UserSkillDTO,
  schemaUserSkillDTO,
} from "../../../shared/specific/DTOs/userSkill/UserSkillDTO";
import { UserSkillUpdateDTO } from "../../../shared/specific/DTOs/userSkill/UserSkillUpdateDTO";
import { UserLanguageUpdateDTO } from "../../../shared/specific/DTOs/userLanguage/userLanguageUpdateDTO";
import {
  UserCertificationDTO,
  schemaUserCertificationDTO,
} from "../../../shared/specific/DTOs/userCertification/UserCertificationDTO";
import { Language } from "../../../shared/common/enums/locale/Language.enum";
import { notifyIf4xxApiErrorDTO } from "../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export const getUserCollaboratorProfile = async (
  idUserCollaborator: number
): Promise<UserCollaboratorProfileDTO> => {
  const { data: userProfile } = await riseApi.get(
    `UserCollaboratorProfile/${idUserCollaborator}`
  );

  return schemaUserCollaboratorProfileDTO.defined().validateSync(userProfile);
};

export const getLoggedUserResumeById = async (
  idResume: number,
  name: string
) => {
  const response = await riseApi.get(
    `UserCollaboratorProfile/LoggedUserCvFile/${idResume}`,
    {
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  saveAs(
    new Blob([response.data], {
      type: "application/octet-stream",
    }),
    name
  );
};

export const getResumeById = async (idResume: number, name: string) => {
  const response = await riseApi.get(
    `UserCollaboratorProfile/CvFile/${idResume}`,
    {
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  saveAs(
    new Blob([response.data], {
      type: "application/octet-stream",
    }),
    name
  );
};

export const getUserCertificationById = async (
  id: number
): Promise<UserCertificationDTO | null> => {
  const { data: userCertification } = await riseApi.get(
    `UserCollaboratorProfile/UserCertifications/${id}`
  );

  return schemaUserCertificationDTO.nullable().validateSync(userCertification);
};

export const createUserCertification = async (
  certificationFile: File[],
  certification: UserCertificationCreateDTO
) => {
  const form = new FormData();
  for (let i = 0; i < certificationFile.length; i++) {
    form.append(`certificationFile`, certificationFile[i]);
  }

  form.append(
    "DateOfAcquisition",
    certification.dateOfAcquisition.toDateString()
  );

  if (certification.expirationDate)
    form.append("ExpirationDate", certification.expirationDate.toDateString());

  form.append(
    "IdSkillCertification",
    certification.idSkillCertification.toString()
  );

  await riseApi.post("UserCollaboratorProfile/UserCertifications", form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCertification = async (id: number) => {
  await riseApi.delete(`UserCollaboratorProfile/UserCertifications/${id}`);
};

export const UpdateUserLanguage = async (
  userSkill: UserLanguageUpdateDTO[]
) => {
  await riseApi.put("UserCollaboratorProfile/SpokenLanguages", userSkill);
};

export const getSpokenLanguageById = async (
  id: number
): Promise<UserLanguageDTO | null> => {
  const { data: userLanguage } = await riseApi.get(
    `UserCollaboratorProfile/SkillOrLanguage/${id}`
  );

  return schemaUserLanguageDTO.nullable().validateSync(userLanguage);
};

export const UpdateUserSkill = async (userSkill: UserSkillUpdateDTO[]) => {
  await riseApi.put("UserCollaboratorProfile/Skills", userSkill);
};

export const getSkillById = async (
  id: number
): Promise<UserSkillDTO | null> => {
  const { data: userSkill } = await riseApi.get(
    `UserCollaboratorProfile/SkillOrLanguage/${id}`
  );

  return schemaUserSkillDTO.nullable().validateSync(userSkill);
};

export const createUserResume = async (
  ResumeFile: File[],
  language: Language
) => {
  const form = new FormData();
  for (let i = 0; i < ResumeFile.length; i++) {
    form.append(`ResumeFile`, ResumeFile[i]);
  }

  form.append(`language`, language);

  await riseApi.post("UserCollaboratorProfile/UserResume", form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteResume = async (id: number) => {
  await riseApi.delete(`UserCollaboratorProfile/UserResume/${id}`);
};
