import { Link, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  StyledEvidenceSection,
  StyledFormSection,
  StyledGrayBox,
  StyledInvoicesInfo,
  StyledLabelChip,
  StyledListFiles,
  StyledProjectInfo,
} from "../../index.styles";
import { formatCurrency } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { DownloadIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { InvoiceFilesDTO } from "../../../../../../../../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { InvoiceTypeTranslationCodeMap } from "../../../../../../../Projects/ProjectPlanningPage/tabs/InvoicesTab/shared/react/mappers/InvoiceTypeTranslationCodeMap";
import { InvoiceFilesTypeEnum } from "../../../../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import { ExternalEnhancedRow } from "../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { InvoiceGroupTable } from "./InvoiceGroupTable";

interface OwnProps {
  isSubmitting: boolean;
  invoiceData: InvoiceDTO;
  invoiceFilesData: InvoiceFilesDTO[];
}

export const StaticFormComponents = ({
  isSubmitting,
  invoiceData,
  invoiceFilesData,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    if (invoiceData.invoiceGroup) {
      const rows: ExternalEnhancedRow[] = invoiceData.invoiceGroup.map(
        (inv, index) => ({
          id: index,
          cells: [
            inv.milestone ?? "-",
            {
              value: inv.billingAmount,
              displayValue:
                formatCurrency({
                  currencySymbol: inv.costCenterPep.subsidiary.currencySymbol,
                  value: inv.billingAmount,
                }) ?? "-",
            },
            inv.costCenterPep.project.name ?? "-",
            inv.costCenterPep.pepCode ?? "-",
            inv.costCenterPep.currency ?? "-",
            inv?.cfop?.name ?? "-",
            inv.managerName ?? "-",
            inv.coodinatorName ?? "-",
            `${inv.costCenterPep.costCenter.name} - ${inv.costCenterPep.costCenter.code}`,
          ],
        })
      );

      return rows;
    }

    return [];
  }, [invoiceData, i18n.language]);

  return (
    <>
      <StyledFormSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.general.customer")}
        </Typography>
        <StyledProjectInfo>
          <TextField
            label={t("customers.keywords.general.customer")}
            value={invoiceData.customer?.tradingName ?? "-"}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={t("customers.keywords.fields.registrationCodeBrazil")}
            value={invoiceData.customer?.registrationCode ?? "-"}
            InputProps={{ readOnly: true }}
          />
        </StyledProjectInfo>
      </StyledFormSection>
      <StyledFormSection>
        <Typography variant="h6" component="div">
          {t("invoice.keywords.fields.invoiceInformations")}
        </Typography>
        <StyledInvoicesInfo>
          <InvoiceGroupTable rows={rows} />
          <TextField
            label={t("general.keywords.fields.totalValue")}
            value={
              formatCurrency({
                currencySymbol:
                  invoiceData.costCenterPep.subsidiary.currencySymbol,
                value: invoiceData.invoiceGroup
                  .map((x) => x.billingAmount)
                  .reduce((a, b) => a + b, 0),
              }) ?? "-"
            }
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={t("invoice.keywords.fields.requestDate")}
            value={
              invoiceData.requestDate
                ?.toDate()
                .toLocaleDateString(i18n.language) || "-"
            }
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={t("invoice.keywords.fields.deadline")}
            value={
              invoiceData.limitDate
                ?.toDate()
                .toLocaleDateString(i18n.language) || "-"
            }
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={t("invoice.keywords.fields.requester")}
            value={invoiceData.requesterLogin?.login ?? "-"}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={t("invoice.keywords.fields.invoiceType")}
            value={
              invoiceData.invoiceType
                ? t(InvoiceTypeTranslationCodeMap[invoiceData.invoiceType])
                : "-"
            }
            InputProps={{ readOnly: true }}
          />
          <TextField
            label={t("invoice.keywords.fields.pepThatWillBeBilled")}
            value={invoiceData.costCenterPepToInvoice.pepCode ?? "-"}
            InputProps={{ readOnly: true }}
          />

          <StyledFormSection>
            <Typography variant="subtitle2" component="div">
              {t("invoice.keywords.fields.competence")}
            </Typography>
            {invoiceData.invoiceCompetence.map((x, i) => {
              const createYm = x.yearMonth;
              const yM = `${createYm.month + 1}/${createYm.year}`;
              return (
                <StyledGrayBox
                  key={i}
                  size="medium"
                  label={<StyledLabelChip>{yM}</StyledLabelChip>}
                />
              );
            })}
          </StyledFormSection>
          <TextField
            label={t("invoice.keywords.fields.launchInstructions")}
            value={invoiceData.launchInstructions ?? "-"}
            InputProps={{ readOnly: true }}
            multiline
            rows={3}
          />
          <TextField
            label={t("invoice.keywords.fields.serviceDescription")}
            value={invoiceData.serviceDescription ?? "-"}
            InputProps={{ readOnly: true }}
            multiline
            rows={3}
          />
          <TextField
            label={t("invoice.keywords.fields.invoiceNote")}
            value={invoiceData.invoiceNote ?? "-"}
            InputProps={{ readOnly: true }}
            multiline
            rows={3}
          />
          <StyledFormSection>
            <Typography variant="subtitle2" component="div">
              {t("invoice.keywords.fields.sendCopyTo")}
            </Typography>
            <div>
              {invoiceData.invoiceCopyToUser.map((x, i) => {
                return (
                  <StyledGrayBox
                    key={i}
                    size="medium"
                    label={
                      <StyledLabelChip>
                        {x.userCollaborator.name ?? "-"}
                      </StyledLabelChip>
                    }
                  />
                );
              })}
            </div>
          </StyledFormSection>
          <StyledFormSection>
            <Typography variant="subtitle2" component="div">
              {t("invoice.keywords.fields.financialResponsibleEmail")}
            </Typography>
            <div>
              {invoiceData.invoiceFinancialResponsible.map((x, i) => {
                return (
                  <StyledGrayBox
                    key={i}
                    size="medium"
                    label={<StyledLabelChip>{x.email ?? "-"}</StyledLabelChip>}
                  />
                );
              })}
            </div>
          </StyledFormSection>
        </StyledInvoicesInfo>
      </StyledFormSection>
      <StyledFormSection>
        <Typography variant="h6" component="div">
          {t("invoice.keywords.fields.evidence")}
        </Typography>
        <StyledEvidenceSection>
          <StyledFormSection>
            <Typography variant="subtitle2" component="div">
              {t("invoice.keywords.fields.invoiceEvidence")}
            </Typography>
            <StyledListFiles>
              {invoiceFilesData
                .filter(
                  (x) => x.invoiceFilesType === InvoiceFilesTypeEnum.Issued
                )
                .map((invFileData, i) => {
                  const elementLength = invFileData?.filePath.split("/").length;
                  return (
                    <StyledGrayBox
                      key={i}
                      size="medium"
                      label={
                        <StyledLabelChip key={i}>
                          {`${
                            invFileData?.filePath.split("/")[elementLength - 1]
                          }`}
                          <Link href={invFileData.filePath} download>
                            <DownloadIconButton disabled={isSubmitting} />
                          </Link>
                        </StyledLabelChip>
                      }
                    />
                  );
                })}
            </StyledListFiles>
          </StyledFormSection>
          <StyledFormSection>
            <Typography variant="subtitle2" component="div">
              {t("invoice.keywords.fields.invoiceRequestEvidence")}
            </Typography>
            <StyledListFiles>
              {invoiceFilesData
                .filter(
                  (x) => x.invoiceFilesType === InvoiceFilesTypeEnum.Request
                )
                .map((invFileData, i) => {
                  const elementLength = invFileData?.filePath.split("/").length;
                  return (
                    <StyledGrayBox
                      key={i}
                      size="medium"
                      label={
                        <StyledLabelChip key={i}>
                          {`${
                            invFileData?.filePath.split("/")[elementLength - 1]
                          }`}
                          <Link href={invFileData.filePath} download>
                            <DownloadIconButton disabled={isSubmitting} />
                          </Link>
                        </StyledLabelChip>
                      }
                    />
                  );
                })}
            </StyledListFiles>
          </StyledFormSection>
        </StyledEvidenceSection>
      </StyledFormSection>
    </>
  );
};
