import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import {
  CommonProjectForm,
  CommonProjectFormRef,
  OnFormSubmitType,
} from "../../shared/react/components/content/CommonProjectForm";
import {
  getProjectById,
  updateProject,
} from "../../../../../../services/projects/projects.service";
import { contractTypeToTranslationCodeMap } from "../../../../../../shared/specific/maps/projects/contractType.maps";
import { typeOfEvidenceToTranslationCodeMap } from "../../../../../../shared/specific/maps/projects/typeOfEvidence.maps";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";
import { getPageFromCurrent } from "../../../../../../services/applicationState/history.service";
import { ProjectStatus } from "../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import { ProjectUpdateDTO } from "../../../../../../shared/specific/DTOs/project/ProjectUpdateDTO";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

type OwnParams = {
  id: string;
};

export const ProjectEditingForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: idFromRoute = "" } = useParams<OwnParams>();
  const [searchParams] = useSearchParams();
  const pageReturnTo = useMemo(() => {
    return searchParams.get("return-to");
  }, []);
  const previousPage = useMemo(() => {
    if (pageReturnTo) return pageReturnTo;

    const location = getPageFromCurrent(1);
    if (!location) return "/projects";
    return location.pathname + location.search;
  }, []);

  const formRef = useRef<CommonProjectFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      if (!id) {
        formRef.current?.setError("general.errors.data.fields.id.invalid");
        formRef.current?.setLoading(false);
        return;
      }

      try {
        const project = await getProjectById(id);
        if (!project) {
          return formRef.current?.setError(
            "projects.errors.data.general.projectDoesNotExist"
          );
        }
        if (
          project.status === ProjectStatus.Canceled ||
          project.status === ProjectStatus.Finalized
        )
          return formRef.current?.setError(
            "projects.errors.data.update.finalizedCanceledProjectCannotBeUpdated"
          );

        setPageTitle(project.name);

        formRef.current?.setProjectData(project);
        formRef.current?.setFormValues({
          customer: project.customer,
          userManager: project.userManager,
          userCoordinator: project.userCoordinator,
          division: project.division,
          subsidiary: project.subsidiary,
          productService: project.productService,
          frame: project.frame,
          code: project.code,
          name: project.name,
          description: project.description,
          contractType: {
            id: project.contractType,
            label: t(contractTypeToTranslationCodeMap[project.contractType]),
          },
          typeOfEvidence: project.typeOfEvidence
            ? {
                id: project.typeOfEvidence,
                label: t(
                  typeOfEvidenceToTranslationCodeMap[project.typeOfEvidence]
                ),
              }
            : null,
          daysForPayment: String(project.daysForPayment),
          billingAmount: project.billingAmount
            ? formatNumber(project.billingAmount, { fractionDigits: 2 })
            : "",
          mv: project.mv ? formatNumber(project.mv, { fractionDigits: 2 }) : "",
          customerNameFromDeal: "",
          userManagerNameFromDeal: "",
          productServiceFromDeal: "",
          divisionFromDeal: "",
          segment: project.segment,
          approvedCredit: {
            id: project.dealApprovedCredit ?? false,
            label: project.dealApprovedCredit
              ? t("general.keywords.general.yes")
              : t("general.keywords.general.no"),
          },
          clientAccount: project.clientAccount ?? "-",
          isInvestmentProject: {
            id: project.isInvestmentProject ?? false,
            label: project.isInvestmentProject
              ? t("general.keywords.general.yes")
              : t("general.keywords.general.no"),
          },
        });
      } catch (error) {
        console.error(error);
        formRef.current?.setError(
          "projects.errors.data.fetch.failedToFetchProjects"
        );
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, []);

  const onSubmit: OnFormSubmitType = async (
    values,
    { setSubmitting },
    { redirectionLinkOnSuccess, projectData }
  ) => {
    if (!projectData) throw new Error("'project' cannot be null on submit.");
    if (!values.contractType)
      throw new Error("'contractType' cannot be null on submit.");

    try {
      await updateProject(
        convertTo<ProjectUpdateDTO>({
          id: id ?? 0,
          idCustomer: values.customer?.id ?? 0,
          idUserManager: values.userManager?.id ?? 0,
          idUserCoordinator: values.userCoordinator?.id,
          idSubsidiary: values.subsidiary?.id ?? 0,
          idDivision: values.division?.id ?? 0,
          idProductService: values.productService?.id ?? 0,
          idFrame: values.frame?.id,
          code: values.code,
          name: values.name,
          description: values.description,
          contractType: values.contractType.id,
          typeOfEvidence: values.typeOfEvidence?.id ?? undefined,
          daysForPayment: parseLocaleNumber(values.daysForPayment),
          billingAmount: parseLocaleNumber(values.billingAmount),
          version: projectData.version,
          mv: parseLocaleNumber(values.mv),
          idSegment: values.segment?.id,
          approvedCredit: values.approvedCredit?.id ?? false,
          isInvestmentProject: values.isInvestmentProject?.id ?? false,
        })
      );

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
      navigate(redirectionLinkOnSuccess ?? previousPage ?? "/projects");
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "projects.errors.data.update.failedToUpdateProject",
      });
      setSubmitting(false);
    }
  };

  return <CommonProjectForm mode="editing" onSubmit={onSubmit} ref={formRef} />;
};
