import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Link, Typography } from "@mui/material";
import { ProfileContext } from "../../context/ProfileContextProvider";
import { StyledLine } from "../../shared/index.styles";
import {
  StyledAreaListLanguages,
  StyledTitleContainer,
  StyledComponentAreas,
  StyledButtonsArea,
} from "./index.styles";
import { DownloadIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { useCreationForm } from "./hooks/useCreationForm";
import { useDeletionForm } from "./hooks/useDeletionForm";
import {
  StyledGrayBox,
  StyledLabelChip,
} from "./components/CreationEditingForm/index.styles";
import { Language } from "../../../../../../../shared/common/enums/locale/Language.enum";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  getLoggedUserResumeById,
  getResumeById,
} from "../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";

export const ResumeArea = () => {
  const { t } = useTranslation();
  const { userCollaboratorProfile, mode } = useContext(ProfileContext);

  const isReadOnly = mode === "viewing";

  const {
    CreationButtonContainer: CreateResumePtbr,
    creationModal: creationModalPtBr,
  } = useCreationForm({
    language: Language.ptBR,
  });

  const {
    CreationButtonContainer: CreateResumeEnUn,
    creationModal: creationModalEnUn,
  } = useCreationForm({
    language: Language.enUS,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm();

  const resumeBr = useMemo(() => {
    const resume = userCollaboratorProfile?.userResume.find((x) =>
      x.filePath.includes(Language.ptBR)
    );

    return resume;
  }, [userCollaboratorProfile?.userResume]);

  const resumeUs = useMemo(() => {
    const resume = userCollaboratorProfile?.userResume.find((x) =>
      x.filePath.includes(Language.enUS)
    );

    return resume;
  }, [userCollaboratorProfile?.userResume]);

  if (!userCollaboratorProfile) return <div> </div>;
  return (
    <StyledComponentAreas>
      <Typography variant="h6">
        <StyledTitleContainer>
          {t("collaboratorProfile.keywords.fields.resume")}
        </StyledTitleContainer>
        <StyledLine />
      </Typography>
      <StyledAreaListLanguages>
        {!isReadOnly && (
          <StyledButtonsArea>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              href="/media/images/pages/userCollaboratorProfile/resumeTemplate/templatePt.docx"
              download
            >
              <Button
                onClick={() => null}
                variant="text"
                startIcon={
                  <img
                    src="/assets/svg/word.svg"
                    alt="powerpoint icon"
                    width="20px"
                    height="20px"
                  />
                }
              >
                {t(
                  "collaboratorProfile.actions.data.general.downloadResumeTemplateInPortuguese"
                )}
              </Button>
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              href="/media/images/pages/userCollaboratorProfile/resumeTemplate/templateEn.docx"
              download
            >
              <Button
                onClick={() => null}
                variant="text"
                startIcon={
                  <img
                    src="/assets/svg/word.svg"
                    alt="powerpoint icon"
                    width="20px"
                    height="20px"
                  />
                }
              >
                {t(
                  "collaboratorProfile.actions.data.general.downloadResumeTemplateInEnglish"
                )}
              </Button>
            </Link>
          </StyledButtonsArea>
        )}

        <StyledButtonsArea>
          {resumeBr ? (
            <StyledGrayBox
              size="medium"
              label={
                <StyledLabelChip>
                  {t("collaboratorProfile.keywords.fields.resumeInPortuguese")}
                  <div>
                    <DownloadIconButton
                      color="primary"
                      onClick={async () => {
                        try {
                          return isReadOnly
                            ? await getResumeById(
                                resumeBr.id,
                                resumeBr.filePath
                              )
                            : await getLoggedUserResumeById(
                                resumeBr.id,
                                resumeBr.filePath
                              );
                        } catch (error) {
                          console.error(error);

                          notifyIf4xxApiErrorDTO({
                            error,
                            defaultMessage:
                              "reports.errors.data.fetch.failedToGenerateReport",
                          });
                        }
                      }}
                    />
                    {!isReadOnly && <DeleteButtonContainer {...resumeBr} />}
                  </div>
                </StyledLabelChip>
              }
            />
          ) : (
            <div>
              {!isReadOnly ? (
                CreateResumePtbr
              ) : (
                <>
                  {t(
                    "collaboratorProfile.keywords.warnings.dontHavePortuguesResume"
                  )}
                </>
              )}
            </div>
          )}
          {resumeUs ? (
            <StyledGrayBox
              size="medium"
              label={
                <StyledLabelChip>
                  {t("collaboratorProfile.keywords.fields.resumeInEnglish")}
                  <div>
                    <DownloadIconButton
                      color="primary"
                      onClick={async () => {
                        try {
                          return isReadOnly
                            ? await getResumeById(
                                resumeUs.id,
                                resumeUs.filePath
                              )
                            : await getLoggedUserResumeById(
                                resumeUs.id,
                                resumeUs.filePath
                              );
                        } catch (error) {
                          console.error(error);

                          notifyIf4xxApiErrorDTO({
                            error,
                            defaultMessage:
                              "reports.errors.data.fetch.failedToGenerateReport",
                          });
                        }
                      }}
                    />
                    {!isReadOnly && <DeleteButtonContainer {...resumeUs} />}
                  </div>
                </StyledLabelChip>
              }
            />
          ) : (
            <div>
              {!isReadOnly ? (
                CreateResumeEnUn
              ) : (
                <>
                  {t(
                    "collaboratorProfile.keywords.warnings.dontHaveEnglishResume"
                  )}
                </>
              )}
            </div>
          )}
        </StyledButtonsArea>
      </StyledAreaListLanguages>
      {creationModalPtBr}
      {creationModalEnUn}
      {deletionModal}
    </StyledComponentAreas>
  );
};
